@page {
    size: auto;
}


@media print {
    body,
    .c-app {
        background-color: unset;
    }

    .c-sidebar,
    .c-header,
    footer {
        display: none !important;
    }
    .c-wrapper,
    .c-main,
    .container-fluid {
        margin: 0 !important;
        padding:0 !important
    }



    .TimeTableApp {
        .hr-department,
        .modal-position,
        .timetableReport {
            display: none;
        }

        .hr-presentation {
            &--timetable {
                overflow-y: unset;
                overflow-x: unset;
            }
        }
    }
}
