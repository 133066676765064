    /* table styles */

    th.mega {
        width: 2000px;
    }

    tr.topLineStrong {
        td {
            border-top: 2px solid #757779;
        }
    }

    table {
        td {
            vertical-align: middle;
        }
    }

    .btnWithIcon {
        transition: all .5s;
        position: relative;

        span.iconWrapper {
            transition: all .5s;
            width: 0px;
            overflow: hidden;
            position: absolute;
            left: 6px;
        }

        &.pendingChanges {
            padding-left: 23px;

            span.iconWrapper {
                width: 14px;
            }
        }
    }

    .buttonSelectListGrouped {
        &--groupContainer {
            margin-bottom: 3px;
            border-bottom: solid 1px $secondary;
        }

        &--groupItem {
            display: inline-block;
            position: relative;
            margin: 2px 4px;
            transition: all 0.5s;
            cursor: pointer;

            &:before {
                content: " ";
                height: 0%;
            }

            &:hover:before,
            &.active:before {
                content: " ";
                z-index: 0;
                position: absolute;
                width: 100%;
                height: 50%;
                left: 0;
                bottom: 3px;
                background-color: #87cefa7a;
                transition: all 0.5s;
            }

            &.active:before {
                height: 20% !important;
            }
        }

        &--items {
            .buttonSelectList {
                button {
                    padding: 0.15rem 0.25rem;
                    margin-bottom: 0.25rem;
                    font-size: 0.7rem;
                    line-height: 1.2;
                }
            }
        }
    }

    .buttonSelectList {

        .btn.active {
            color: white !important;
        }

        .btn-outline-primary:hover,
        .btn-outline-secondary:hover,
        .btn-outline-success:hover,
        .btn-outline-danger:hover,
        .btn-outline-warning:hover,
        .btn-outline-info:hover,
        .btn-outline-light:hover,
        .btn-outline-dark:hover {
            background-color: transparent !important;
            background-image: none !important;
        }

        .btn-outline-primary:hover {
            color: #007bff;
            border-color: #007bff;
        }

        .btn-outline-secondary:hover {
            color: #6c757d !important;
            border-color: #6c757d !important;
        }

        .btn-outline-success:hover {
            color: #28a745 !important;
            border-color: #28a745 !important;
        }

        .btn-outline-danger:hover {
            color: #dc3545 !important;
            border-color: #dc3545 !important;
        }

        .btn-outline-warning:hover {
            color: #ffc107 !important;
            border-color: #ffc107 !important;
        }

        .btn-outline-info:hover {
            color: #17a2b8 !important;
            border-color: #17a2b8 !important;
        }

        .btn-outline-light:hover {
            color: #f8f9fa !important;
            border-color: #f8f9fa !important;
        }

        .btn-outline-dark:hover {
            color: #343a40 !important;
            border-color: #343a40 !important;
        }
    }

    .inputDuration {
        label {
            margin-bottom: 0px;
        }

        >div {
            border: solid 1px #e4e7ea;
            border-radius: 0.2rem;
        }

        input {
            width: 100%;
            border: none;
            color: #5c6873;
            /* border-bottom: solid 1px grey; */
            text-align: right;
        }

        width: 45px;
    }

    // timepicker
    .tp {
        text-align: left;
        display: flex;
        flex-direction: row;

        .tp_hour,
        .tp_min {
            display: inline-block;
            height: auto;
            border: solid 1px black;
        }

        .tp_hour {
            @include media-breakpoint-up(sm) {
                width: 134px;
            }

            width: 60%;
        }

        .tp_min {
            @include media-breakpoint-up(sm) {
                width: 68px;
            }

            width: 40%;
        }

        .tp_hour .tp_hour_scrollwrapper,
        .tp_min .tp_min_wrapper {
            @include media-breakpoint-up(sm) {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
            }

            display: grid;
            grid-template-columns: auto auto auto auto;
            grid-template-rows: 25px 25px 25px 25px 25px 25px;
        }

        .tp_min .tp_min_wrapper .tp_min_linebreak {
            display: none;
        }

        .tp_min .tp_min_wrapper {
            grid-template-columns: auto auto auto !important;
        }

        .tp_hour .tp_hour_item,
        .tp_min .tp_min_item {
            @include media-breakpoint-up(sm) {
                line-height: 1.8;
            }

            flex: 22px;
            text-align: center;
            line-height: 1.4;
        }

        .tp_hour .tp_hour_item:hover,
        .tp_min .tp_min_item:hover {
            background-color: rgb(160, 151, 151);
            cursor: pointer;
        }

        .disabled {
            background-color: white;
            color: white;
        }

        .tp_hour_item.disabled:hover {
            background-color: unset;
        }

        .selected {
            background-color: lightskyblue;
        }

        .tp_min .tp_min_wrapper .tp_min_linebreak {
            width: 100%;
        }
    }

    .fileUpload {
        .dropArea {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            vertical-align: middle;
            padding: 15px;
            border: dotted 2px blue;
            border-radius: 5px;

            p {
                margin: 0;
            }
        }
    }

    .profile {
        &__favoriteButton {
            $height: 2.5rem;
            /*line-height: $height;*/
            /*
        svg {
            height:$height;
            width:$height;
            line-height: $height;
        }
        */
        }

        &__detail {
            fieldset {
                legend {
                    font-size: 1rem;
                    border-bottom: solid 1px black;
                }
            }
        }
    }

    .profilePicture {
        .profile__favoriteButton {
            position: absolute;
            top: -5px;
            right: 0;
            z-index: 1;

            @include media-breakpoint-up(sm) {
                top: 0;
                right: 0;
            }
        }

        &__content {
            border-width:4px;
            width: 70px;
            height: 70px;
            border-color: #adadad;
            border-style: solid;
            background: #ededed;



            &:hover {
                background: #e0e0e0;
            }

            >svg.icon {
                width: 26px !important;
                height: 26px !important;
            }

            @include media-breakpoint-up(sm) {
                width: 85px;
                height: 85px;

                >svg.icon {
                    width: 35px !important;
                    height: 35px !important;
                }
            }

        }
    }


    .ProfileApp {
        &_ListItems {
            a {
                text-decoration: none;
                color: inherit;

                &:hover {
                    color: inherit;
                }
            }
        }
    }

    .navigation {
        &-inline {
            display: block;
            border-bottom: solid 3px $gray-300;
            padding: 0;

            &>li {
                display: inline-block;
                padding: 10px 14px;
                margin-bottom: -3px;
                border-bottom: solid 3px transparent;

                &:hover {
                    border-bottom-color: $gray-600;
                    cursor: pointer;
                }

                &.active {
                    border-bottom-color: $primary;
                    font-weight: bold;
                }
            }
        }
    }

    .jobs {
        &__fileUpload__preview {
            height: 150px;
        }

        .fileUpload {
            .dropArea {
                height: 150px;
            }
        }
    }

    .editorJs__wrapper {
        border: 1px solid var(--cui-input-border-color, #b1b7c1);
        padding: 5px;
        border-radius: 0.25rem;
        padding-left: 70px;
    }

    .sidebar {
        max-height: 100vh;
        overflow: hidden;
        display: flex;

        &-content {
            overflow-y: auto;
        }

        &.sidebar-lg {
            --cui-sidebar-width: 40rem;
            max-width: 100%;
        }
    }

    .dropdownbox {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid #e4e7ea;
            background-color: #f8f9fa;
            cursor: pointer;

            .dropdownbox__header__title {
                margin: 0;
            }

            .dropdownbox__header__icon {
                transition: transform 0.3s ease-in-out;
            }

            &.active {
                .dropdownbox__header__icon {
                    transform: rotate(180deg);
                }
            }
        }

        &__content {
            padding: 0.5rem 1rem;
            border-bottom: 1px solid #e4e7ea;
            border-left: 1px solid #e4e7ea;
            border-right: 1px solid #e4e7ea;
        }
    }


    .conversations {
        &__list {
            flex-basis: 350px;
        }

        &__chat {
            &__messages {
                &__item {
                    border-radius: 15px;
                    border-width: 1px;
                    padding: 10px;
                    margin-bottom: 15px;

                    &:not(.my) {
                        border-end-start-radius: 0px;
                        margin-right: 50px;
                        border-color: #4e4e4e;
                        background-color: #cbcbcb;
                    }

                    &.my {
                        text-align: right;
                        border-end-end-radius: 0;
                        margin-left: 50px;
                        border-color: #6c7967;
                        background-color: #b1c4a9;
                    }

                    &--subtext {
                        font-size: .8em;
                        color: white;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .progress-steps-inline {
        &__step {
            // padding: 0px 3px 3px 3px;
            // /* border: solid 1px gray; */
            // background: whitesmoke;
            // border-radius: 0px;

            // &::after {
            //     content: "";
            //     height: 10px;
            //     width: 10px;
            //     background: whitesmoke;
            //     transform: translateY(3px) translateX(8px) rotate(45deg);
            // }
        }
    }